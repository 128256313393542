import React from 'react'

export default () => (
  <div className="su-spoiler su-spoiler-style-default su-spoiler-icon-plus-square-2 su-spoiler-closed"
       data-scroll-offset={0} style={{'display': 'none'}}>
    <div className="su-spoiler-title" tabIndex={0} role="button">
      <span className="su-spoiler-icon"/>Centrum medyczne Wołomin
    </div>
    <div className="su-spoiler-content su-u-clearfix su-u-trim"><p/>
      <h2><strong>Centrum medyczne w Wołominie oferuje poniższy zakres usług medycznych:</strong></h2>
      <ul>
        <li>
          <h3><strong>Centrum Medyczne Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> Ginekolog Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> Dobry ginekolog Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> gabinet ginekologiczny Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> USG 3D Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> przychodnia ginekologiczna Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> szkoła rodzenia Wołomin</strong></h3>
        </li>
        <li>
          <h3><strong> ginekolog wołomin prywatnie</strong></h3>
        </li>
        <li>
          <h3><strong> szpital wołomin ginekologia</strong></h3>
        </li>
        <li>
          <h3><strong> usg wołomin</strong></h3>
        </li>
      </ul>
      <h2><strong>W ofercie naszej przychodni ginekologicznej dostępne są usługi w temacie:</strong></h2>
      <ul>
        <li><em><strong>Centrum Medyczne Wołomin</strong></em></li>
        <li><em><strong>Ginekolog Wołomin</strong></em></li>
        <li><em>usg ginekologiczne Wołomin</em></li>
        <li><em>badanie ginekologiczne Wołomin</em></li>
        <li><em>Lekarz ginekolog Wołomin</em></li>
        <li><em><strong>Dobry ginekolog Wołomin</strong></em></li>
        <li><em>cytologia Wołomin</em></li>
        <li><em>wizyta u ginekologa Wołomin</em></li>
        <li><em>klinika ginekologiczna Wołomin</em></li>
        <li><em><strong>gabinet ginekologiczny Wołomin</strong></em></li>
        <li><em>ginekolog prywatnie Wołomin</em></li>
        <li><em>usg w ciąży Wołomin</em></li>
        <li><em>ginekologia Wołomin</em></li>
        <li><em>Ginekolog cennik Wołomin</em></li>
        <li><em>usg prenatalne Wołomin</em></li>
        <li><em>USG ginekologiczne Wołomin</em></li>
        <li><em><strong>USG 3D Wołomin</strong></em></li>
        <li><em>USG 4D Wołomin</em></li>
        <li><em>USG 3 trymestr Wołomin</em></li>
        <li><em>USG 2 trymestr Wołomin</em></li>
        <li><em><strong>przychodnia ginekologiczna Wołomin</strong></em></li>
        <li><em>USG 1 trymestr Wołomin</em></li>
        <li><em>USG I trymestr Wołomin</em></li>
        <li><em>USG II trymestr Wołomin</em></li>
        <li><em>USG III trymestr Wołomin</em></li>
        <li><em>USG pierwszy trymestr Wołomin</em></li>
        <li><em>USG drugi trymestr Wołomin</em></li>
        <li><em>USG trzeci trymestr Wołomin</em></li>
        <li><em><strong>szkoła rodzenia Wołomin</strong></em></li>
        <li><em>badania w ciąży Wołomin</em></li>
        <li><em>ktg cena Wołomin</em></li>
        <li><em>ktg Wołomin</em></li>
        <li><em>test pappa Wołomin</em></li>
        <li><em>usg połówkowe Wołomin</em></li>
        <li><em>badanie ginekologiczne w ciąży Wołomin</em></li>
        <li><em>usg płodu Wołomin</em></li>
      </ul>
      <h2><strong>Dodatkowe tagi w ramach usług ginekologicznych naszej Kliniki ginekologicznej Tiliamed:</strong></h2>
      <table dir="ltr" border={1} cellSpacing={0} cellPadding={0}>
        <colgroup>
          <col width={100}/>
        </colgroup>
        <tbody>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog prywatnie Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog prywatnie Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg ciąży&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;gabinet ginekologiczny Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>gabinet ginekologiczny Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;lekarz ginekolog Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>lekarz ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;konsultacja położnicza Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>konsultacja położnicza Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia ginekologiczna Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia ginekologiczna Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 4d Wołomin&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 4d Wołomin</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d Wołomin&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 3d Wołomin</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg dopochwowe</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u ginekologa Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u ginekologa Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny gabinet ginekologiczny Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny gabinet ginekologiczny Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg jajników</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog badanie&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog badanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przychodnia ginekologiczna Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przychodnia ginekologiczna Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;u ginekologa Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>u ginekologa Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;szkoła rodzenia&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>szkoła rodzenia</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny ginekolog&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg pochwy&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg pochwy</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezpochwowe&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezpochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg genetyczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg genetyczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg połówkowe Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg połówkowe Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;dobry ginekolog Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>dobry ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie dopochwowe&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg w ciąży Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg w ciąży Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekologia&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekologia</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne transwaginalne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne transwaginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie pochwy&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie pochwy</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg w 3d&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg w 3d</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg Wołomin&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg Wołomin</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatne gabinety ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatne gabinety ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie u ginekologa Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie u ginekologa Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg prenatalne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg prenatalne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;u ginekologa badanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>u ginekologa badanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ciąży Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;dobry lekarz ginekolog Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>dobry lekarz ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;gabinety ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>gabinety ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnie ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnie ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog dziecięcy Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog dziecięcy Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog usg Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg 3d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie usg 3d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekologia dziecięca&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekologia dziecięca</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog prywatny&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog prywatny</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter ekg cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>holter ekg cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie macicy Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie macicy Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie transwaginalne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie transwaginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg u ginekologa&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg u ginekologa</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przyrządy ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przyrządy ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;dopochwowe usg Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>dopochwowe usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządu rodnego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządu rodnego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg doppler Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg doppler Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg genetyczne cena Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg genetyczne cena Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d 4d Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg 3d 4d Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajnikow Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajnikow Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania w ciąży&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badania w ciąży</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog pierwsza wizyta&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog pierwsza wizyta</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciążowe Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ciążowe Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg pochwy&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg pochwy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;głowica usg dopochwowa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>głowica usg dopochwowa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;klinika ginekologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>klinika ginekologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;aparat do usg dopochwowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>aparat do usg dopochwowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży 3d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg ciąży 3d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg scriningowe ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg scriningowe ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekologiczne badanie usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekologiczne badanie usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przyrząd do usg dopochwowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przyrząd do usg dopochwowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;podstawowe badania ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>podstawowe badania ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg położnicze Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg położnicze Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przychodnia ginekolog Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przychodnia ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg jajników&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg jajników</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;sonda dopochwowa Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>sonda dopochwowa Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter ciśnieniowy cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>holter ciśnieniowy cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe cena Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe cena Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog online Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog online Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog cennik Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog cennik Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg macicy Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg macicy Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi w ciazy Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi w ciazy Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie cytologiczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie cytologiczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne na czym polega&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne na czym polega</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kobieta u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kobieta u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg waginalne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg waginalne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;spirala antykoncepcyjna cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>spirala antykoncepcyjna cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;endokrynolog ginekolog Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>endokrynolog ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ktg cena Wołomin&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ktg cena Wołomin</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania usg w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania usg w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg prenatalne cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg prenatalne cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;dr ginekolog&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>dr ginekolog</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg wewnątrzmaciczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg wewnątrzmaciczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania genetyczne w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania genetyczne w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jajniki usg Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jajniki usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe głowica&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe głowica</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 4d cena Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg 4d cena Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przebieg badania ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przebieg badania ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatna klinika ginekologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatna klinika ginekologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test pappa cena Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>test pappa cena Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt usg 3d Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt usg 3d Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy i przydatków&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy i przydatków</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży 4d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg ciąży 4d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatne kliniki ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatne kliniki ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg tv narządów rodnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg tv narządów rodnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie dopochwowe usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie dopochwowe usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie przezpochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie przezpochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg w 4d Wołomin&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg w 4d Wołomin</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne dziewica&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne dziewica</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;klinika ginekologii Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>klinika ginekologii Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d cena Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg 3d cena Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przez pochwe Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przez pochwe Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia ginekologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia ginekologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;sonda dopochwowa usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>sonda dopochwowa usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe co wykrywa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe co wykrywa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg piersi cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg brzucha cena&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg brzucha cena</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cennik ginekolog&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cennik ginekolog</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przydatków&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg przydatków</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg endowaginalne&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg endowaginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie jajników usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie jajników usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg płodu&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg płodu</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d w ciąży&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 3d w ciąży</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne w którym dniu cyklu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne w którym dniu cyklu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg szyjki macicy Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg szyjki macicy Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie położnicze Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie położnicze Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;sonda ginekologiczna Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>sonda ginekologiczna Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne wziernikiem&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne wziernikiem</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda badanie ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda badanie ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg brzucha&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg brzucha</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg domaciczne&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg domaciczne</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatna wizyta u ginekologa cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatna wizyta u ginekologa cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg cena Wołomin&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg cena Wołomin</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d ciąża Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg 3d ciąża Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;na czym polega usg ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>na czym polega usg ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe ciąża&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe ciąża</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog wizyta&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog wizyta</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje usg dopochwowe prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje usg dopochwowe prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ciąża usg Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ciąża usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;w gabinecie u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>w gabinecie u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;najlepszy ginekolog&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>najlepszy ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ortopedyczne&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg ortopedyczne</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka antykoncepcyjna cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka antykoncepcyjna cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych transwaginalne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych transwaginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje wizyta u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje wizyta u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania cytologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania cytologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania genetyczne płodu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania genetyczne płodu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne koszt&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne koszt</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej a miesiączka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej a miesiączka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;konsultacja ginekologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>konsultacja ginekologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg brzucha przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg brzucha przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy nieciężarnej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy nieciężarnej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje usg ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje usg ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog od a do z&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog od a do z</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;transwaginalne usg&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>transwaginalne usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;infolinia ginekologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>infolinia ginekologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne przez odbyt&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne przez odbyt</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych podczas miesiączki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych podczas miesiączki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pessar cena Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pessar cena Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży transwaginalne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ciąży transwaginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg barku cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg barku cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatna wizyta u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatna wizyta u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kliniki ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kliniki ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dziecka Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dziecka Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jaydess cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>jaydess cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do badania usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do badania usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;porady ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>porady ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia dla kobiet Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia dla kobiet Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;lekarz ginekolog dla mężczyzn&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>lekarz ginekolog dla mężczyzn</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy nieciężarnej i przydatków&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy nieciężarnej i przydatków</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zabiegi ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zabiegi ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;dopochwowe badanie usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>dopochwowe badanie usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg 4d Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg 4d Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej jajniki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej jajniki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ceny wizyt u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ceny wizyt u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;na czym polega badanie usg ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>na czym polega badanie usg ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;odczulanie cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>odczulanie cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie waginalne&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie waginalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg przez pochwe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg przez pochwe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jąder Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jąder Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog sobota&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog sobota</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;choroby ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>choroby ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy co wykrywa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy co wykrywa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg Wołomin&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg Wołomin</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;lekarz położnik&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>lekarz położnik</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog prywatnie cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog prywatnie cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;macica usg Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>macica usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne pochwy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne pochwy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników cena&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia ginekologiczna online&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia ginekologiczna online</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie prenatalne usg Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie prenatalne usg Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg brzucha dziecka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg brzucha dziecka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg prywatnie Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg prywatnie Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg w ciąży 3d Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg w ciąży 3d Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg doodbytnicze u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg doodbytnicze u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg wewnętrzne&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg wewnętrzne</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg w ciąży&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kiedy usg w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg piersi&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg piersi</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg genetyczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg genetyczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;sonda do usg dopochwowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>sonda do usg dopochwowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka levosert&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>wkładka levosert</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg pęcherza moczowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg pęcherza moczowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;naprotechnologia cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>naprotechnologia cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej na czczo&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej na czczo</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt badań prenatalnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt badań prenatalnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezpochwowe u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezpochwowe u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;rodzaje usg ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>rodzaje usg ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt wizyty u ginekologa prywatnie Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt wizyty u ginekologa prywatnie Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych co wykrywa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych co wykrywa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg wewnątrzpochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg wewnątrzpochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje wizyta u ginekologa prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje wizyta u ginekologa prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg tkanek miękkich&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg tkanek miękkich</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;dobry ginekolog endokrynolog&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>dobry ginekolog endokrynolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg tv przygotowanie do badania&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg tv przygotowanie do badania</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg sutka&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg sutka</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne na czym polega&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne na czym polega</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie dopochwowe w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie dopochwowe w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej dziecka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej dziecka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne w którym tygodniu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne w którym tygodniu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie pochwy u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie pochwy u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cytologia cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;4d usg&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>4d usg</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsze badanie ginekologiczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pierwsze badanie ginekologiczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej ciąża&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej ciąża</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg ciąży cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d kiedy&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 3d kiedy</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne w którym dniu cyklu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne w którym dniu cyklu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;nowy gabinet ginekologiczny&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>nowy gabinet ginekologiczny</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;polski ginekolog&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>polski ginekolog</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne usg pochwy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne usg pochwy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekologia polska&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekologia polska</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test harmony cena&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>test harmony cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezpochwowe przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezpochwowe przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog dyskretnie Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog dyskretnie Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;endometrioza usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>endometrioza usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej u dziecka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej u dziecka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg prostaty&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg prostaty</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezciemiączkowe cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezciemiączkowe cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe przed miesiączką&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe przed miesiączką</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka domaciczna levosert&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka domaciczna levosert</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg stawu barkowego cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg stawu barkowego cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;biopsja pierś cena&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>biopsja pierś cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg Wołomin&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena usg Wołomin</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u ginekologa pierwsza&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u ginekologa pierwsza</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe jajniki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe jajniki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekologia choroby&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekologia choroby</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg jamy brzusznej cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg jamy brzusznej cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;do którego tygodnia ciąży usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>do którego tygodnia ciąży usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena badań prenatalnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena badań prenatalnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;chirurg ginekolog Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>chirurg ginekolog Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg doppler&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg doppler</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jąder jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jąder jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezpochwowe cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezpochwowe cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg dopochwowe cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg dopochwowe cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg u dziewicy Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg u dziewicy Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ciąża pierwsza wizyta u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ciąża pierwsza wizyta u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg szyjki Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg szyjki Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej podczas okresu Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej podczas okresu Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników w którym dniu cyklu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników w którym dniu cyklu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg cennik&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg cennik</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;co wykrywa usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>co wykrywa usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;3 badanie prenatalne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>3 badanie prenatalne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi kiedy&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg piersi kiedy</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do badania usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do badania usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena prywatnej wizyty u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena prywatnej wizyty u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie cytologiczne wyniki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie cytologiczne wyniki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog telefon&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog telefon</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 4d koszt&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 4d koszt</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog online porady&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog online porady</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ktg cena&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie ktg cena</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;podstawowe badania u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>podstawowe badania u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wyniki usg ciąży&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>wyniki usg ciąży</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe w ciąży do którego tygodnia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe w ciąży do którego tygodnia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pessar ginekologiczny cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pessar ginekologiczny cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka domaciczna jaydess&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka domaciczna jaydess</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg 3d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>kiedy usg 3d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog skawina&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog skawina</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie narządów rodnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie narządów rodnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka mirena cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka mirena cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajnikow na czym polega&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajnikow na czym polega</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg Wołomin&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg Wołomin</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pani ginekolog&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>pani ginekolog</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne co wykrywa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne co wykrywa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dziewicy&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg dziewicy</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;leki ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>leki ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wady płodu&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wady płodu</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi cennik&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi cennik</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog dla mężczyzn&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog dla mężczyzn</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg piersi&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena usg piersi</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne zdjęcia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne zdjęcia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter ekg 12 kanałowy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>holter ekg 12 kanałowy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi krosno&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi krosno</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania genetyczne w ciazy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania genetyczne w ciazy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u pani ginekolog&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u pani ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi koszt&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg piersi koszt</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy i jajników&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy i jajników</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jądra&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jądra</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg szyjki macicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg szyjki macicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie jajników u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie jajników u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter ekg cennik&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>holter ekg cennik</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej przygotowanie Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej przygotowanie Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekologia i położnictwo&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekologia i położnictwo</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt prywatnej wizyty u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt prywatnej wizyty u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cennik usg&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cennik usg</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;rak szyjki macicy usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>rak szyjki macicy usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej cennik&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej cennik</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg jamy brzusznej przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg jamy brzusznej przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pogotowie ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pogotowie ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do badania usg brzucha&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do badania usg brzucha</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie dopochwowe u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie dopochwowe u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi Wołomin&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kobieta ginekolog&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kobieta ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt badania prenatalnego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt badania prenatalnego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg nerek&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg nerek</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej Wołomin&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej Wołomin</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;do którego tygodnia usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>do którego tygodnia usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;gabinet usg&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>gabinet usg</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg 4d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie usg 4d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog badanie piersi&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog badanie piersi</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cennik usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cennik usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne wewnętrzne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne wewnętrzne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog 24h&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog 24h</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg jąder&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg jąder</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;stan zapalny szyjki macicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>stan zapalny szyjki macicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi piła&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg piersi piła</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje wizyta prywatna u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje wizyta prywatna u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;do którego tygodnia ciąży robi się usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>do którego tygodnia ciąży robi się usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie u lekarza&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie u lekarza</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prowadzenie ciąży&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prowadzenie ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne kolposkopia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne kolposkopia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania w czasie ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania w czasie ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatnie kobiety&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatnie kobiety</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg brzucha&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena usg brzucha</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;laparoskopia ginekologia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>laparoskopia ginekologia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg piersi&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>kiedy usg piersi</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia płynna&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cytologia płynna</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej podczas miesiączki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej podczas miesiączki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter ekg 3 kanałowy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>holter ekg 3 kanałowy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test hpv&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>test hpv</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 2d&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg 2d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg 3d cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg 3d cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przygotowanie&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog wołomin prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog wołomin prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;11 tydzień ciąży usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>11 tydzień ciąży usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne przez powłoki brzuszne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne przez powłoki brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przed usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przed usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg doppler prącia&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg doppler prącia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg miednicy mniejszej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg miednicy mniejszej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg cena&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie usg cena</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg powłok brzusznych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg powłok brzusznych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak przygotować się do badania ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak przygotować się do badania ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena wkładki antykoncepcyjnej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena wkładki antykoncepcyjnej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania genetyczne płodu cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania genetyczne płodu cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży kiedy&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg ciąży kiedy</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie kolposkopowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie kolposkopowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg pochwy cena&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg pochwy cena</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog ciąża&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog ciąża</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej biała podlaska&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej biała podlaska</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej psa cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej psa cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg 3d&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena usg 3d</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne usg dopochwowe film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne usg dopochwowe film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;specjalizacja położnicza dla położnych testy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>specjalizacja położnicza dla położnych testy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg układu moczowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg układu moczowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;lekarz ginekolog online&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>lekarz ginekolog online</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter 12 kanałowy&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>holter 12 kanałowy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;centrum medyczne&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>centrum medyczne</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przed badaniem usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przed badaniem usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy do ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kiedy do ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne usg dopochwowe czy brzuszne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne usg dopochwowe czy brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatne usg&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>prywatne usg</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania genetyczne niepłodność cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania genetyczne niepłodność cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt usg piersi&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>koszt usg piersi</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test pappa cennik&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>test pappa cennik</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia szyjki macicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia szyjki macicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zdjecie usg ciazy&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zdjecie usg ciazy</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg genetyczne płodu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg genetyczne płodu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ciąży</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog cytologia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog cytologia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg miednicy małej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg miednicy małej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg brzucha cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg brzucha cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi zakopane&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi zakopane</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg ginekologiczne cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg ginekologiczne cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt badania usg&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt badania usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;monitoring cyklu cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>monitoring cyklu cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsze badanie u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pierwsze badanie u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wynik usg dopochwowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wynik usg dopochwowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsze usg&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>pierwsze usg</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej koszt&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej koszt</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg 4d&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena usg 4d</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników dziewica&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników dziewica</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe badania&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe badania</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena implantu antykoncepcyjnego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena implantu antykoncepcyjnego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka domaciczna jaydess cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka domaciczna jaydess cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg serca płodu&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg serca płodu</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe jak wcześnie wykrywa ciąże&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe jak wcześnie wykrywa ciąże</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do badania ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do badania ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;opis usg ciąży&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>opis usg ciąży</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg brzucha przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg brzucha przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;harmony test cena&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>harmony test cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;biopsja endometrium cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>biopsja endometrium cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ciąża ginekolog&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ciąża ginekolog</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zdjecie usg&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zdjecie usg</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe co pokazuje&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe co pokazuje</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny gabinet ginekologiczny&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny gabinet ginekologiczny</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena badania prenatalnego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena badania prenatalnego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;doppler badanie cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>doppler badanie cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie cytologiczne cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie cytologiczne cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pessar cena&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pessar cena</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ciąża usg dopochwowe do którego tygodnia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ciąża usg dopochwowe do którego tygodnia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak odczytać usg ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak odczytać usg ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena cytologii&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena cytologii</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej przygotowanie pacjenta&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej przygotowanie pacjenta</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne cennik&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne cennik</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne jak się przygotować&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne jak się przygotować</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg bioderek cena&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg bioderek cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;lekarz endokrynolog ginekolog&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>lekarz endokrynolog ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak się przygotować do badania ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak się przygotować do badania ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne przez odbyt&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne przez odbyt</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;holter 12 odprowadzeniowy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>holter 12 odprowadzeniowy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe do którego tygodnia ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe do którego tygodnia ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne piła&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne piła</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;monitoring owulacji cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>monitoring owulacji cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena badania usg&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena badania usg</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne kiedy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne kiedy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak odczytać usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak odczytać usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog radzi&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog radzi</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe jak się przygotować&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe jak się przygotować</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzuszne&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej ile kosztuje&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej ile kosztuje</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zdjęcia ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zdjęcia ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe forum&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe forum</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie dopochwowe film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie dopochwowe film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe boli&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe boli</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg dopochwowe wyniki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg dopochwowe wyniki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;sonda brzuszna&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>sonda brzuszna</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena biopsji&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena biopsji</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg 4d cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg 4d cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jąder cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg jąder cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zalecenia przed usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zalecenia przed usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ciąży</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;szczepionka na hpv&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>szczepionka na hpv</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zdjęcie usg 3d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>zdjęcie usg 3d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie płodu&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie płodu</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg pochwy&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg pochwy</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt usg brzucha&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt usg brzucha</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe podczas okresu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe podczas okresu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatne gabinety ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatne gabinety ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ekg cennik&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ekg cennik</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej wyniki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej wyniki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda badanie usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda badanie usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wyniki usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wyniki usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników jak się przygotować&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników jak się przygotować</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych przez powłoki brzuszne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych przez powłoki brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg pochwy co wykrywa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg pochwy co wykrywa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg kostki cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg kostki cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie 3d cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie 3d cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;opis badania usg&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>opis badania usg</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przez powłoki brzuszne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przez powłoki brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena usg dopochwowego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cena usg dopochwowego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej koło&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej koło</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;doppler cena badania&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>doppler cena badania</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi trójmiasto&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi trójmiasto</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;nadżerka macicy objawy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>nadżerka macicy objawy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog porady&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog porady</strong></span></div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;do którego tygodnia robi sie usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>do którego tygodnia robi sie usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda usg ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda usg ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak przygotować się do ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak przygotować się do ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog opinie&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog opinie</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsze usg w ciąży dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pierwsze usg w ciąży dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;centrum ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>centrum ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg 4d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>kiedy usg 4d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej jelita&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej jelita</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d koszt&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 3d koszt</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;echo serca z kontrastem&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>echo serca z kontrastem</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d i 4d&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg 3d i 4d</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie 4d cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>badanie 4d cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog sztum prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog sztum prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wynik usg piersi&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>wynik usg piersi</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg brzucha koszt&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg brzucha koszt</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi pila&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg piersi pila</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;spirala jaydess cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>spirala jaydess cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt implantu antykoncepcyjnego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>koszt implantu antykoncepcyjnego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej noworodka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej noworodka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg sztum&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg sztum</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi wyniki&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi wyniki</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zdjecie usg 4d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>zdjecie usg 4d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;spirala antykoncepcyjna mirena cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>spirala antykoncepcyjna mirena cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej co obejmuje&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej co obejmuje</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile kosztuje badanie usg 3d&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile kosztuje badanie usg 3d</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;testy specjalizacja ginekologiczna dla położnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>testy specjalizacja ginekologiczna dla położnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg planetarne&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg planetarne</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg dopochwowe</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi&quot;}">
            <h4><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test troponinowy&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>test troponinowy</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ciąży połówkowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ciąży połówkowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;krwawienie po usg dopochwowym w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>krwawienie po usg dopochwowym w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne dziewczynek&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne dziewczynek</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne 3d&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne 3d</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 4d zdjęcia&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 4d zdjęcia</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej zalecenia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej zalecenia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;czy ginekolog robi usg piersi&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>czy ginekolog robi usg piersi</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog szybko&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog szybko</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka domaciczna cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka domaciczna cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;krwawienie po usg dopochwowym&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>krwawienie po usg dopochwowym</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;koszt usg 4d&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>koszt usg 4d</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;centrum medyczne&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>centrum medyczne</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg piersi przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;powłoki brzuszne&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>powłoki brzuszne</strong></span></div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne przez powłoki brzuszne przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne przez powłoki brzuszne przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog cennik&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog cennik</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne skriningowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne skriningowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej żary&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej żary</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;spirala ginekologiczna cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>spirala ginekologiczna cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pessar cena&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pessar cena</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;co obejmuje usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>co obejmuje usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog męski&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ginekolog męski</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny ginekolog&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena testu pappa&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena testu pappa</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg ciąży&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>kiedy usg ciąży</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe wyniki&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe wyniki</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej ostróda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej ostróda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda usg jajników&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda usg jajników</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg genetyczne w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg genetyczne w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zakopane ginekolog prywatnie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zakopane ginekolog prywatnie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg u ginekologa jak wyglada&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg u ginekologa jak wyglada</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg miednicy mniejszej jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg miednicy mniejszej jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test prenatalny harmony&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>test prenatalny harmony</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do usg ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do usg ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak się przygotować do ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak się przygotować do ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne jak się przygotować&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne jak się przygotować</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne kiedy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne kiedy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia badanie&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia badanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;co wykaże usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>co wykaże usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe a dziewictwo&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe a dziewictwo</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia lbc cena&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia lbc cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia prywatnie cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia prywatnie cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;tv usg&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>tv usg</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy cena&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg macicy cena</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej espumisan&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej espumisan</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia jednowarstwowa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia jednowarstwowa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania prenatalne 4d&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania prenatalne 4d</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;biopsja ginekologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>biopsja ginekologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny gabinet ginekologiczny jarosław&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny gabinet ginekologiczny jarosław</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie dopochwowe usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie dopochwowe usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników przez powłoki brzuszne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników przez powłoki brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;czy badanie dopplera jest refundowane&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>czy badanie dopplera jest refundowane</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u seksuologa cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u seksuologa cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przed usg jamy brzusznej należy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przed usg jamy brzusznej należy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia cienkowarstwowa cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia cienkowarstwowa cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny gabinet ginekologiczny&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny gabinet ginekologiczny</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badania endokrynologiczne cennik&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badania endokrynologiczne cennik</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg piersi cykl&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg piersi cykl</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej a okres&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej a okres</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak często można robić usg dopochwowe w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak często można robić usg dopochwowe w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile trwa usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile trwa usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pogotowie antykoncepcyjne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pogotowie antykoncepcyjne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej u noworodka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej u noworodka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ekg 12 kanałowe&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>ekg 12 kanałowe</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny gabinet ginekologiczny stalowa wola&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny gabinet ginekologiczny stalowa wola</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pessar ginekologiczny ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pessar ginekologiczny ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ultrasonografia w położnictwie i ginekologii&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ultrasonografia w położnictwie i ginekologii</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej jaki lekarz&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej jaki lekarz</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wynik badania usg&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wynik badania usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezbrzuszne&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezbrzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;czy usg dopochwowe boli&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>czy usg dopochwowe boli</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;po jakim czasie usg dopochwowe wykryje ciążę&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>po jakim czasie usg dopochwowe wykryje ciążę</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne miednicy mniejszej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne miednicy mniejszej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog dla mezczyzn&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog dla mezczyzn</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;opinie o lekarzu ginekologu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>opinie o lekarzu ginekologu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe kiedy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe kiedy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne histeroskopia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne histeroskopia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne ciąża&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne ciąża</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej co wykazuje&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej co wykazuje</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg a ciąża&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg a ciąża</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg piersi cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg piersi cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg pochwy dziewica&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg pochwy dziewica</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie przed usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie przed usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;genetyczne usg&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>genetyczne usg</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy najlepiej zrobić usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kiedy najlepiej zrobić usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u genetyka po badaniach prenatalnych&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u genetyka po badaniach prenatalnych</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe a ciąża&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe a ciąża</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przychodnia ginekolog&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przychodnia ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wkładka antykoncepcyjna mirena cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wkładka antykoncepcyjna mirena cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg brzucha noworodka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg brzucha noworodka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie usg pochwy film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie usg pochwy film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne z pełnym pęcherzem&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne z pełnym pęcherzem</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;hsg usg&quot;}">
            <h4><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>hsg usg</strong></span>
            </h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej picie wody&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej picie wody</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia ginekologiczna zamość&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia ginekologiczna zamość</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;interpretacja usg ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>interpretacja usg ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg 3d zdjęcia&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>usg 3d zdjęcia</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ginekolog tabletki antykoncepcyjne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ginekolog tabletki antykoncepcyjne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u proktologa cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u proktologa cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta recepturowa&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta recepturowa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;założenie pessara cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>założenie pessara cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia cennik&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cytologia cennik</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie w gabinecie ginekologicznym&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie w gabinecie ginekologicznym</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;laparoskopia w ginekologii&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>laparoskopia w ginekologii</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe filmik&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe filmik</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne kiedy wykonać&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne kiedy wykonać</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia cienkowarstwowa lbc&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia cienkowarstwowa lbc</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsze badanie usg&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pierwsze badanie usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia ginekologiczna usk&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia ginekologiczna usk</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przychodnia morena ginekolog&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przychodnia morena ginekolog</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;szpital zdroje ginekologia opinie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>szpital zdroje ginekologia opinie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wyjecie implantu antykoncepcyjnego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wyjecie implantu antykoncepcyjnego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy badanie ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kiedy badanie ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsza wizyta u lekarza w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pierwsza wizyta u lekarza w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;co wykrywa usg macicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>co wykrywa usg macicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;opis badania usg ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>opis badania usg ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne przez odbyt przygotowanie&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne przez odbyt przygotowanie</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kiedy usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;konsultacja endokrynologiczna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>konsultacja endokrynologiczna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usługi położnicze&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usługi położnicze</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przygotowanie do ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przygotowanie do ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej ile trwa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej ile trwa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wyniki usg płodu&quot;}">
            <h4><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>wyniki usg płodu</strong></span></h4>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;specjalizacja ginekologiczna testy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>specjalizacja ginekologiczna testy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia ginekologiczno poloznicza&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia ginekologiczno poloznicza</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych kiedy robić&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych kiedy robić</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prywatny gabinet ginekologiczny przemyśl&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prywatny gabinet ginekologiczny przemyśl</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;monitorowanie cyklu u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>monitorowanie cyklu u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;kiedy usg przez powłoki brzuszne w ciąży&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>kiedy usg przez powłoki brzuszne w ciąży</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne kiedy robić&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne kiedy robić</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak wygląda usg macicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak wygląda usg macicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe zdjęcia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe zdjęcia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;czy usg dopochwowe wykryje raka&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>czy usg dopochwowe wykryje raka</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe czy boli&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe czy boli</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg drugi trymestr&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg drugi trymestr</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy u dziewicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy u dziewicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;jak przygotować się do usg ginekologicznego&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>jak przygotować się do usg ginekologicznego</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;na czym polega usg&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>na czym polega usg</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;przed wizytą u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>przed wizytą u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;z mama u ginekologa&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>z mama u ginekologa</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jamy brzusznej a ciąża&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jamy brzusznej a ciąża</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg narządów rodnych kiedy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg narządów rodnych kiedy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne dopochwowe film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne dopochwowe film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg jajników kiedy robić&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg jajników kiedy robić</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;interpretacja usg płodu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>interpretacja usg płodu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cena badania hsg&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>cena badania hsg</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;pierwsze usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>pierwsze usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg macicy przez powłoki brzuszne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg macicy przez powłoki brzuszne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg transwaginalne film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg transwaginalne film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ile trwa badanie usg jamy brzusznej&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ile trwa badanie usg jamy brzusznej</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;gbs w ciąży cena&quot;}">
            <div><span style={{
              fontSize: '14pt',
              fontFamily: 'arial, helvetica, sans-serif'
            }}><strong>gbs w ciąży cena</strong></span></div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;poradnia ginekologiczna polna&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>poradnia ginekologiczna polna</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ciąża badanie ginekologiczne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ciąża badanie ginekologiczne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe co moze wykryc&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe co moze wykryc</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;test troponinowy cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>test troponinowy cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;obraz usg przed miesiączką&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>obraz usg przed miesiączką</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;wizyta u pulmonologa cena&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>wizyta u pulmonologa cena</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ocena dobrostanu płodu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>ocena dobrostanu płodu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe kiedy robić&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe kiedy robić</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;co to jest usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>co to jest usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne usg pochwy film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne usg pochwy film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg dopochwowe w którym dniu cyklu&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg dopochwowe w którym dniu cyklu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;zdjęcie usg płodu&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>zdjęcie usg płodu</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg ginekologiczne film&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg ginekologiczne film</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie ginekologiczne ginekologia&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie ginekologiczne ginekologia</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;badanie jajników jak wygląda&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>badanie jajników jak wygląda</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;po jakim czasie usg dopochwowe wykrywa ciążę&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>po jakim czasie usg dopochwowe wykrywa ciążę</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;lekarz ginekolog meski&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>lekarz ginekolog meski</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;prześwietlenie macicy&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>prześwietlenie macicy</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;czy usg dopochwowe jest bolesne&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>czy usg dopochwowe jest bolesne</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;usg przezpochwowe&quot;}">
            <div><span
              style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>usg przezpochwowe</strong></span>
            </div>
          </td>
        </tr>
        <tr>
          <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cytologia czy usg dopochwowe&quot;}">
            <div><span style={{fontSize: '14pt', fontFamily: 'arial, helvetica, sans-serif'}}><strong>cytologia czy usg dopochwowe</strong></span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <p/>
    </div>
  </div>
)
